import { CSSProperties, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { blue, green } from '@mui/material/colors';

import { ThemeMode } from '/common/models';
import { ThemeProvider } from '/common/theme';

export const useSpaceTableStyles = makeStyles((theme: Theme) => {
  const currentThemeMode = theme.palette.mode as ThemeMode;

  return {
    selectedLabel: {
      marginLeft: 5,
      verticalAlign: 'middle',
      color: green[500],
    },
    toolsColumn: {
      width: 130,
      textAlign: 'center',
    },
    spaceName: ThemeProvider.getAppropriateStyles(
      currentThemeMode,
      {
        cursor: 'pointer',
        color: '#337ab7',
        '&:hover': {
          textDecoration: 'none',
          color: '#23527c',
        },
      },
      {
        cursor: 'pointer',
        color: blue[400],
        '&:hover': {
          textDecoration: 'underline',
          color: blue[400],
        },
      }
    ) as CSSProperties,
  };
});
