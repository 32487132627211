import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, CircularProgress, TextField } from '@mui/material';
import { reach } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { QuerySpacesResponse, Space } from '@playq/octopus2-apps';
import { GenericFailure } from '@playq/services-shared';

import { SelectDialog } from '/shared/SelectDialog';
import { TextFieldValidator } from '/shared/TextFieldValidator';
import { useSpaceCreate, spacesServiceQueryKeys, useSpaceUpdate } from '/api';
import { snackbarService } from '/common/snackbarService';
import { activeSpaceToolkit } from '/store';
import { useStateChange } from '/common/useStateChange';
import { confirmAboutUnsavedChanges } from '/helpers';

import { ISpaceProps, spaceEditSchema } from './types';
import { useSpaceComponentStyles } from './styles';

interface RootState {
  name: string;
  description: string;
}

export const SpaceComponent: FC<ISpaceProps> = (props) => {
  const { space: originSpace, onUpdateSpace, onClose, spacesQueryKey } = props;
  const firstRender = useRef(true);
  const activeSpace = useSelector(activeSpaceToolkit.selectors.activeSpace);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const classes = useSpaceComponentStyles();

  const [state, setState] = useState<RootState>(() => getInitState(originSpace));
  const [space, setSpace] = useState<Space>(originSpace);

  const noChanges = useStateChange({ state, initialState: getInitState(originSpace) });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setSpace(originSpace);
    setState(getInitState(originSpace));
  }, [originSpace]);

  const { mutate: createSpace, isLoading: saving } = useSpaceCreate({
    onSuccess: (res: Space) => {
      setSpace(res);
      onUpdateSpace(res);
      snackbarService.success(`Space has been created`);
    },
    onError: (err: Error | GenericFailure) => snackbarService.error(err.message),
    removeQueriesKeys: spacesServiceQueryKeys,
  });

  const { mutate: updateSpace, isLoading: updating } = useSpaceUpdate({
    onSuccess: (_res, nSpace) => {
      if (spacesQueryKey === undefined) {
        queryClient.removeQueries(spacesServiceQueryKeys, { exact: true });
        return;
      }
      onUpdateSpace(nSpace);
      const cachedData = queryClient.getQueryData<QuerySpacesResponse>(spacesQueryKey);
      if (!cachedData) {
        return;
      }
      const spaces = new QuerySpacesResponse(cachedData.serialize());
      spaces.entities = spaces.entities.map((s: Space) => {
        if (s.id.id === nSpace.id.id) {
          return nSpace;
        }
        return s;
      });
      queryClient.setQueryData(spacesServiceQueryKeys, spaces);

      if (activeSpace && nSpace.id.id === activeSpace.id.id) {
        dispatch(activeSpaceToolkit.actions.put(nSpace.serialize()));
      }

      snackbarService.success(`Space: ${nSpace.name} has been updated`);
    },
    onError: (err: GenericFailure | Error) => snackbarService.error(err.message),
  });

  function getInitState(s: Space): RootState {
    return {
      name: s.name ?? '',
      description: s.description ?? '',
    };
  }

  const isCreating = space?.id === undefined;

  const handleSaveSpace = () => {
    if (!spaceEditSchema.isValidSync(state)) {
      return;
    }

    if (!isCreating) {
      const s = new Space(space.serialize());
      s.name = state.name.trim();
      s.description = state.description;
      updateSpace(s);
    } else {
      const s = new Space();
      s.name = state.name.trim();
      s.description = state.description;
      createSpace(s.toSpaceContent());
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setState({ ...state, name });
  };

  const handleDescChange = (event: ChangeEvent<HTMLInputElement>) => {
    const description = event.target.value;
    setState({ ...state, description });
  };

  const handleSubmitForm = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSaveSpace();
  };

  const isSaveDisabled = useMemo(
    () => !spaceEditSchema.isValidSync(state) || saving || updating || noChanges,
    [state, saving, updating, noChanges]
  );

  const handleClose = () => {
    confirmAboutUnsavedChanges(onClose, noChanges);
  };

  return (
    <SelectDialog
      title={<>{isCreating ? 'Create Space' : 'Edit Space'}</>}
      headerAction={
        <div className={classes.actionWrapper}>
          <Button
            variant='contained'
            color={isCreating ? 'success' : 'primary'}
            data-testid='button-save'
            disabled={isSaveDisabled}
            onClick={handleSaveSpace}
          >
            {isCreating ? 'Create' : 'Save'}
          </Button>
          {(saving || updating) && <CircularProgress size={24} className={classes.actionButton} />}
        </div>
      }
      content={
        <>
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {originSpace.id && (
            <TextField
              className={classes.field}
              label='Space ID'
              value={originSpace.id.id}
              fullWidth={true}
              disabled={true}
            />
          )}
          <form onSubmit={handleSubmitForm}>
            <TextFieldValidator
              className={classes.field}
              schema={reach(spaceEditSchema, 'name')}
              required={true}
              label='Space name'
              inputProps={{ 'data-testid': 'input-name' }}
              value={state.name}
              autoFocus={true}
              fullWidth={true}
              onChange={handleNameChange}
            />
            <TextField
              className={classes.field}
              label='Space description'
              inputProps={{ 'data-testid': 'input-description' }}
              value={state.description || ''}
              fullWidth={true}
              onChange={handleDescChange}
            />
          </form>
        </>
      }
      open={true}
      onClose={handleClose}
      maxWidth='sm'
    />
  );
};
