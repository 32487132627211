import { FC, useCallback, useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';

import { SpaceID } from '@playq/octopus-common';
import { Space } from '@playq/octopus2-apps';
import { SpacePromotions } from '@playq/octopus2-apps-utility';

import { throwGenericFailure } from '/helpers';
import { services2 } from '/api/services2';
import { confirmDialog } from '/common/ConfirmDialog';
import { SelectDialog } from '/shared/SelectDialog';
import { CollectionTableAnyFilterDetached, CollectionTableColumnSelectionDetached } from '/shared/Table';

import { ISpacesListProps } from './types';
import { useSpacesList } from './useSpacesList';
import { SpacesTable } from './components/SpacesTable';
import { SpaceDeletion } from './components/SpaceDeletion';
import { SpacesColumnType } from './components/types';
import { useSpacesListStyles } from './styles';

export const SpacesList: FC<ISpacesListProps> = (props) => {
  const { onSelect, onCreate, onEdit, initialQuery, onClose, activeSpaceID } = props;
  const [spacePromotions, setSpacePromotions] = useState<SpacePromotions | undefined>();
  const [currentSpaceID, setCurrentSpaceID] = useState<SpaceID | undefined>();
  const [columns, setColumns] = useState<SpacesColumnType[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [enableQuerySpaces, setEnableQuerySpaces] = useState<boolean>(true);
  const didCancel = useRef(false);

  const {
    spaces,
    pending,
    total,
    canWrite,
    entitiesProcessing,
    query,
    spacesQueryKey,

    setQuery,
    deleteSpace,
    refresh,
  } = useSpacesList(initialQuery, { enabled: enableQuerySpaces });

  useEffect(
    () => () => {
      didCancel.current = true;
    },
    []
  );

  const classes = useSpacesListStyles();

  const handleUseDefault = () => onSelect();

  const handleCreate = () => onCreate();

  const handleEdit = (space: Space) => onEdit(space, spacesQueryKey);

  const handleDelete = (space: Space) => {
    setCurrentSpaceID(space.id);
    services2.spacesUtility.spacePromotions(space.id).then((data) =>
      data.bifold((res) => {
        if (didCancel.current) {
          return;
        }
        if (res.byApp.length === 0) {
          confirmDialog({
            title: `DELETE ${space.name}?`,
            text: `Are you sure you want to delete the ${space.name}?`,
            closeButton: { label: 'NO' },
            successButton: { label: 'YES' },
            onSuccess: () => deleteSpace(space.id),
          });
        } else {
          setSpacePromotions(res);
        }
      }, throwGenericFailure)
    );
  };

  const submitSpaceDeletion = useCallback(
    (spaceID: SpaceID) => {
      setSpacePromotions(undefined);
      setCurrentSpaceID(undefined);
      deleteSpace(spaceID);
    },
    [deleteSpace]
  );

  const isHidden = useCallback((label: string) => hiddenColumns.includes(label), [hiddenColumns]);

  const onSetColumns = useCallback((newColumns: SpacesColumnType[]) => setColumns(newColumns), []);

  return (
    <SelectDialog
      title='Select Space'
      open={true}
      onClose={onClose}
      headerAction={
        <>
          <CollectionTableAnyFilterDetached query={query} processing={pending} setQuery={setQuery} />
          <CollectionTableColumnSelectionDetached
            columns={columns}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={setHiddenColumns}
          />
        </>
      }
      actions={
        <div className={classes.actionsWrapper}>
          <Button variant='contained' onClick={handleCreate} data-testid='button-create'>
            Create
          </Button>
          <Button variant='contained' onClick={handleUseDefault} color='primary'>
            Use Default
          </Button>
        </div>
      }
      content={
        <>
          <SpacesTable
            entities={spaces}
            entitiesProcessing={entitiesProcessing}
            activeSpaceID={activeSpaceID}
            total={total}
            query={query}
            pending={pending}
            canWrite={canWrite}
            queryKeys={spacesQueryKey}
            setEnableQuerySpaces={setEnableQuerySpaces}
            onSelectSpace={onSelect}
            onQueryChange={setQuery}
            onDeleteSpace={handleDelete}
            onEditSpace={handleEdit}
            refetch={refresh}
            isHidden={isHidden}
            onSetColumns={onSetColumns}
            onClose={onClose}
          />
          {currentSpaceID && spacePromotions && (
            <SpaceDeletion spaceID={currentSpaceID} spacePromotions={spacePromotions} onDelete={submitSpaceDeletion} />
          )}
        </>
      }
    />
  );
};
