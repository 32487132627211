import { object, string } from 'yup';

import { Space, SpacesSort, SpacesSortField } from '@playq/octopus2-apps';
import { SpaceID } from '@playq/octopus-common';

import { IQuery } from '/common/models';

export interface ISpacesQuery extends IQuery<SpacesSortField, SpacesSort> {}

const fieldRequiredSchema = (field: string) => string().trim().required(`${field} is required!`);

export const spaceEditSchema = object().shape({
  name: fieldRequiredSchema('Name'),
});

export interface ISpaceProps {
  space: Space;
  spacesQueryKey?: unknown[];
  onUpdateSpace: (space: Space) => void;
  onClose: VoidFunction;
}

export interface ISpaceSelectProps {
  initialQuery?: ISpacesQuery;
  activeSpaceID?: SpaceID;

  onSelect: (space?: Space) => void;
  onClose: VoidFunction;
}

export interface ISpacesListProps {
  initialQuery?: ISpacesQuery;
  activeSpaceID?: SpaceID;

  onClose: VoidFunction;
  onEdit: (space: Space, spacesQueryKey: unknown[]) => void;
  onCreate: () => void;
  onSelect: (space?: Space) => void;
}

export interface IGlobalSpacesProps {
  onClose: VoidFunction;
}
