import styled from 'styled-components';
import { CircularProgress, DialogContent } from '@mui/material';
import { Done as DoneIcon, Error as ErrorIcon } from '@mui/icons-material';

export const List = styled.ul`
  margin-top: 0;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 500px;
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  margin: 0 ${(props) => props.theme.spacing(1)} -2px;
  color: ${(props) => props.theme.palette.error.main};
  font-size: 14px;
`;

export const FinishIcon = styled(DoneIcon)`
  margin: 0 ${(props) => props.theme.spacing(1)};
  color: ${(props) => props.theme.palette.success.main};
  font-size: 14px;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  margin-right: ${(props) => props.theme.spacing(1)};
`;
